.eula-modal {
  .ant-modal-body {
    padding: 0;
    max-height: unset;
    overflow: unset;
  }
  .eula-body {
    padding: 25px;
    max-height: 70vh;
    overflow-y: auto;
  }
}
