.video-container {
  .ant-table-wrapper {
    border: 1px solid #e2e2e2;
  }
  .video-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 25;
    -webkit-box-orient: vertical;
  }
  .video-actions.cec-icon {
    width: 15px;
    height: 15px;
  }
}

.video-form-item {
  text-align: center;
}

.subscribe-icon {
  position: relative;
  .anticon-stop {
    position: absolute;
    bottom: 0px;
    right: 20px;
    background: white;
  }
}
