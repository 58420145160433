.permission-container {
  .first-column {
    display: flex;
    align-items: center;
    .cec-icon {
      margin-right: 8px;
      height: 40px;
      width: 40px;
    }
    .primary-row {
      font-weight: 700;
      color: #0271bc;
      text-overflow: ellipsis;
      overflow: hidden;
      text-decoration: none;
      max-width: 100%;
    }
  }
  .second-column {
    color: #414141;
    font-size: 12px;
    line-height: 14px;
    border-left: 1px solid #dbdbdb;
    padding-left: 8px;
    .ant-tag {
      line-height: 14px;
    }
  }
  .expand-icon {
    color: #919395;
    width: 14px;
    height: 14px;
    &.expanded {
      transform: rotate(90deg);
    }
  }
  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }
  .permission-list {
    margin-top: 15px;
    border-left: 1px solid #dbdbdb;
    display: none;
  }
  .expanded-row {
    td {
      vertical-align: top;
    }
    .permission-list {
      display: block;
    }
  }
}

.permission-list {
  .ant-list-item {
    padding: 8px;
    border: none;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
