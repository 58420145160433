.cec-icon {
  fill: currentColor;
  transition: transform .2s;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  &.onClick {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}