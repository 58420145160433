.dashboard-container {
  height: calc(100vh - 180px);
  .ant-select {
    min-width: 200px;
  }
  .ant-card {
    height: 100%;
  }
  .cards-container {
    .ant-card {
      height: fit-content;
    }
  }
  .no-data-table {
    .ant-table-tbody > tr > td {
      border: none;
    }
  }
  .cec-icon {
    color: #6cb0ec;
    width: 22px;
    height: 22px;
    font-size: 22px;
    vertical-align: middle;
    &.round-icon {
      border: 1px solid #6cb0ec;
      border-radius: 50%;
    }
    &.cec-icon-chevron {
      transform: rotate(180deg);
    }
  }
  .first-letter-column {
    text-transform: capitalize;
  }
  .global-row {
    color: #6cb0ec;
  }
  .action-status-column {
    .SUCCESS {
      color: #359e35;
    }
    .INPROGRESS {
      color: #ffec3d
    }
    .ERROR {
      color: #f93010;
    }
  }
}
.dashboard-actions-container {
  .ant-card-body {
    max-height: 300px;
    overflow: hidden auto;
  }
}
