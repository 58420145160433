.tag-legend {
  .ant-form-item-label {
    font-weight: 500;
    margin-top: 10px;
  }
  .ant-badge-status-text {
    font-weight: 500;
    font-size: 12px;
  }
}
