.info-bar {
  display: flex;
  justify-content: space-between;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #babcbe;
  padding: 0 25px;
  .info-bar-left {
    display: flex;
    align-items: center;
    padding: 12px 0;
    .cec-icon {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
  }
  
  .primary-title {
    color: #414141;
    font-size: 16px;
  }
  .sub-title {
    margin-top: 2px;
    color: #919395;
    font-size: 14px;
  }
  .info-bar-right {
    .ant-btn {
      margin-left: 10px;
      height: 100%;
      .cec-icon {
        width: 32px;
        height: 32px;
        color: #0271bc;
      }
    }
  }
}