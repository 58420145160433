.doc-container {
  .attachment-container {
    padding: 10px;
    border: 1px solid #E8E8EF;
    border-radius: 2px;
    background: #F8F9F9;
    a {
      display: block;
      line-height: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}