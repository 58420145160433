.websocket-container {
  background-color: #fff;
  & > div {
    width: 700px;
  }
  .ant-collapse-header {
    font-weight: bold;
  }
  .output-SENT b {
    color: green;
  }
  .output-CLOSED b {
    color: red;
  }
  .output-REC b {
    color: blue;
  }
  .button-row {
    text-align: right;
    .ant-btn {
      margin-left: 5px;
    }
  }
}
