.lab-config-list-item {
  .ant-select {
    width: 250px;
  }
}

.lab-config-cascader-popup {
  .ant-cascader-menu {
    min-width: 250px;
    height: fit-content;
    max-height: 60vh;
    min-height: 180px;
  }
}
