.poster {
  height: 590px;
  position: relative;
  .poster-left {
    height: 100%;
    background: url('../../../assets/img/home/banner-text-bg.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .poster-table {
      width: 392px;
      margin-left: calc(106% - 535px);
      .poster-title {
        color: #fff;
        line-height: 1.1;
        font-size:  48px;
      }
      .poster-body {
        color: #fff;
        line-height: 1.2;
        font-size: 18px;
        word-break: break-word;
      }
    }
  }
  
  .poster-right {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #314351;
  }
}

.events-container {
  width: 100%;
  bottom: -3px;
  position: absolute;
  z-index: 2;
  color: #fff;
  font-size: 12px;
  .event-item {
    display: inline-block;
    padding: 5px 15px;
    width: 500px;
    border: 1px solid #E8E8EF;
    height: 60px;
    overflow: hidden;
    margin-left: 5px;
    background: #666666;
    border-bottom: 0px;
    transition: .5s;
    &:hover {
      height: 240px;
    }
    .event-title {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
      word-break: break-all;
    }
    .event-body {
      word-break: normal;
      overflow-x: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 19px;
    }
  }
}
