.account-info-container {
  .ant-form-item {
    margin-bottom: 15px;
  }
  .ant-form-item-label > label {
    color: #637582;
    font-weight: bold;
  }
  .ant-input[disabled] {
    color: #000;
  }
}