.pagination-item {
  .anticon {
    width: 24px;
    height: 100%;
    line-height: 22px;
  }
}

.ant-pagination-prev, .ant-pagination-next {
  &:not(.ant-pagination-disabled) .anticon {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      color: #1890FF;
    }
  }
}