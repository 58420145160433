.inventory-item {
  display: flex;
  align-items: center;
  .inventory-status {
    display: inline-block;
    margin-right: 5px;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 1px solid #000;
    flex-shrink: 0;
    &.status-ACTIVE {
      background-color: #359e35;
    }
    &.status-ERROR {
      background-color: #f93010;
    }
    &.status-INPROGRESS, &.status-NONE.configstatus-INPROGRESS {
      background-color: #ffec3d;
    }
  }
  .configstate {
    &.DONE {
      display: none;
    }
    &.INPROGRESS {
      font-style: italic;
      text-transform: lowercase;
      &::before {
        content: "...";
      }
    }
  }
}

.highlight-collapse-header.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #1890ff !important;
  font-size: 14px !important;
}
