.app-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 89;

  .app-bar {
    height: 50px;
    background-color: #33424f;
    display: flex;
    align-items: center;
    overflow: auto hidden;
  }

  .app-logo {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    color: #E8E8EF;
    font-size: 22px;
    font-weight: bold;
    padding-right: 20px;
    border: 1px solid transparent;
    min-width: fit-content;
    .cec-icon {
      position: relative;
      width: 100px;
      height: 25px;
      margin: 0 10px 2px;
    }
    > span {
      position: relative;
      margin-left: 16px;
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: rgba(255,255,255,0.4);
        left: -16px;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      .cec-icon {
        transform: scale(1.11);
      }
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0.2);
      border-color: #009eef;
    }
  }

  .app-bar-left {
    display: flex;
    height: 100%;
    flex-shrink: 0;
    > * {
      position: relative;
      &::before {
        position: absolute;
        top: 5px;
        height: 40px;
        right: -1px;
        content: "";
        border-left: 1px solid #676767;
      }
    }
  }

  .app-bar-right {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: flex-end;
    .ant-badge-count {
      border-radius: 3px;
    }
    > * {
      position: relative;
      &::before {
        position: absolute;
        top: 5px;
        height: 40px;
        left: 0px;
        content: "";
        border-left: 1px solid #676767;
      }
    }
    .domain-avatar {
      padding: 0 20px;
      display: flex;
      align-items: center;
      &::before {
        border: none;
      }
      .ant-avatar-square {
        background: #359e35;
        font-size: 18px;
      }
    }
  }

  .modal-wrap {
    position: fixed;
    z-index: 99;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .app-menu {
    top: 0;
    max-width: 100%;
    width: 100% !important;
    background-color: #2c3339;
    display: flex;
    color: #fff;
    width: 100%;
    padding: 35px 0;
  
    > * {
      min-width: 200px;
      flex: 1;
      padding: 0 25px;
      border-right: 1px solid #676767;
      max-height: 70vh;
      overflow-y: auto;
      &:last-child {
        border-color: transparent;
      }
      .menu-header {
        padding-bottom: 5px;
        margin-bottom: 16px;
        border-bottom: 1px solid #676767;
        font-size: 23px;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      .menu-column-li {
        margin: 0;
        &::before {
          display: none;
        }
        .icon-button {
          width: 100%;
          padding: 5px;
          text-align: left;
          white-space: unset;
          .first-row {
            color: #f0f2f4;
          }
          .cec-icon {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
          }
        }
      }
    }
  }  
}
