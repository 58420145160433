.layout-sider.ant-layout-sider {
  padding-bottom: 0;
  background-color: #f5f6f7;
  color: #414141;
  border-right: 1px solid #babcbe;
  .ant-menu {
    background-color: inherit;
    border: none;
    &.sider-header {
      .ant-menu-item {
        padding-left: 25px;
        font-size: 16px;
        color: #414141;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        .ant-menu-item-icon {
          flex: none;
        }
        .ant-menu-title-content {
          margin-left: 0;
        }
      }
    }
    .ant-menu-item-divider {
      border-color: #babcbe;
    }
  }
  &.ant-layout-sider-collapsed {
    .ant-menu-item-divider {
      border-color: transparent;
    }
    .sider-content {
      width: 0;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
    }
    .ant-layout-sider-trigger {
      .cec-icon {
        transform: rotate(180deg);
      }
    }
  }
  .sider-content {
    flex: 1;
    overflow-y: auto;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    padding: 10px 15px 10px 10px;
    .ant-picker {
      width: 100%;
    }
    .ant-form {
      padding: 0 15px;
      .ant-collapse {
        margin: -12px -16px;
      }
    }
    .ant-collapse-header {
      margin-bottom: -12px;
    }
    .ant-collapse-extra {
      margin-right: 10px;
      .ant-btn-text {
        background-color: transparent;
      }
    }
    .ant-collapse .ant-form-item {
      margin-bottom: 0;
      .ant-checkbox-wrapper {
        display: flex;
        & + .ant-checkbox-wrapper {
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }
  .ant-layout-sider-trigger {
    position: absolute;
    top: calc(50% - 30px);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;
    color: #ddd;
    background-color: transparent;
    &:hover {
      color: #009eef;
    }
    .cec-icon {
      width: 30px;
      height: 30px;
    }
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
}

.ant-layout {
  height: calc(100vh - 180px);
  background-color: #fff;
  .ant-layout-content {
    padding: 10px 25px;
    overflow: hidden auto;
    .title {
      padding: 12px 0;
      font-size: 20px;
      color: #314351;
      font-weight: bold;
    }
  }
} 