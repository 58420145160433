.icon-button.ant-btn {
  position: relative;
  height: 100%;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  color: #babcbe !important;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid transparent;
  transition: color .5s ease;
  
  .cec-icon {
    width: 30px;
    height: 30px;
  }

  .first-row.with-second-row {
    color: #f0f2f4;
  }

  .second-row {
    font-size: 12px;
  }

  > *:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    .cec-icon {
      transform: scale(1.11);
    }
  }

  &.active {
    background-color: #2c3339;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: #009eef;
  }
}
