.file-upload-modal {
  .eula-form-item {
    .ant-form-item-label >label {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .eula-body {
    max-height: 200px;
    padding: 15px;
    margin-bottom: 8px;
    background-color: #fff;
    overflow-y: auto;
  }
}