.severity-cell {
  position: relative;
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    &.Critical {
      background-color: #d33f37;
      color: #fff;
    }
    &.Major {
      background-color: #e29434;
      color: #313b45;
    }
    &.Minor {
      background-color: #dbd44f;
      color: #313b45;
    }
  }
}