.card-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  font-size: 12px;
  .ant-card {
    line-height: 1.2;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .ant-card-head {
      font-size: 14px;
      color: #6cb0ec;
      font-weight: bold;
    }
    .ant-card-body {
      padding: 12px 24px;
      flex: 1;
    }
    .ant-card-actions > li {
      margin: 12px;
      display: flex;
      justify-content: flex-end;
      .cec-icon {
        color: #6cb0ec;
        width: 20px;
        height: 20px;
      }
    }
    .detail-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .detail-label {
        color: #8c8989;
      }
      .detail-content {
        line-break: anywhere;
        font-size: 12px;
        font-weight: bold;
      }
      &.primary {
        .detail-content {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.action-menu {
  min-width: 200px;
  .ant-dropdown-menu-item {
    color: #6cb0ec;
  }
  .ant-dropdown-menu-item-disabled {
    color: #c2d9eb;
  }
  .ant-dropdown-menu-item-divider {
    &:first-child, &:last-child {
      display: none;
    }
  }
}
