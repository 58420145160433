.analytics-report-container {
  .table-container {
    .report-container {
      background: #fff;
      flex: 1 1;
      overflow-y: auto;
      border: 1px solid #e2e2e2;
      padding: 8px;
    }
  }
}
