.chatbot-container {
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: #ffffff;
  .chatbot-toolbar {
    height: 60px;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    font: 400 15px Arial;
    color: #625959;
    text-shadow: 1px 1px #b6afaf;

    .chatbot-toolbar-left {
      display: flex;
      align-items: center;

      .cec-icon {
        width: 60px;
        height: 40px;
      }
    }

    .chatbot-toolbar-right {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #0271bc;

      &>* {
        padding: 0 5px;
      }

      .anticon-to-top {
        transform: rotate(90deg);
      }

      .anticon-vertical-align-top {
        transform: rotate(-90deg);
      }
    }
  }

  &.chatbot-attached {
    position: sticky;
    top: 50px;
    height: calc(100vh - 50px);
    min-width: 400px;
    max-width: 800px;
    width: 33%;
    flex-shrink: 0;
    transition: width 1s ease;

    &.chatbot-expanded {
      width: 50%;
    }
  }

  &.chatbot-detached {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 800px;
    height: 600px;
    z-index: 199;
  }

  .chatbot-body {
    height: calc(100% - 60px);

    .chatbot-messages-container {
      background-color: #f5f6f7;
      height: calc(100% - 75px);

      .ant-spin-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .chatbot-footer {
      height: 75px;
      padding: 5px 5px 10px 5px;

      .ant-input {
        border-radius: 5px;
      }

      .ant-input-group-addon {
        background-color: transparent;

        .ant-btn {
          background-color: transparent;
          border-radius: 5px;
          box-shadow: none;

          &:hover {
            background-color: rgba(0, 158, 239, .07);
          }

          .cec-icon {
            width: 20px;
            height: 20px;
          }
        }
      }

      .chatbot-footer-info {
        color: #696969;
        font-size: 10px;
        padding: 5px 20px;
      }
    }
  }
}