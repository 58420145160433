.last-form-item:last-child {
  margin-bottom: 0;
}

.audit-select-column {
  display: flex;
  align-items: center;
  .ant-select {
    margin-left: 5px;
  }
}
