.chatbot-message-container {
  margin: 10px;
  display: flex;

  .message-bubble {
    position: relative;
    max-width: 90%;
    border-radius: 10px;
    padding: 10px;
    word-break: break-word;

    .message-content {
      line-height: 20px;

      p:last-child {
        margin-bottom: 0;
      }

      ul {
        list-style: disc;
        padding-left: 12px;
      }

      ol {
        list-style: decimal;
        padding-left: 16px;
      }

      p code {
        background-color: #ebf0f5;
        display: inline-block;
        line-height: 1;

        border-radius: 3px;
        padding: 4px;
      }

      pre {
        overflow-y: auto;
        background-color: #ebf0f5;
        border-color: #ebf0f5;
        color: #293f51;
        border-radius: 5px;
        padding: 8px;
        margin-top: 4px;
        margin-bottom: 8px;
        display: block;
        line-height: 1.7;
        white-space: pre-wrap;

        code {
          background-color: transparent;
          color: #293f51;
          padding: 0;
        }
      }

      blockquote {
        border-left: 2px solid #ebf0f5;
        color: #293f51;
        margin: 4px 0;
        padding: 8px 8px 0 16px;
      }

      a {
        text-decoration: underline;
      }
    }

    .message-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:has(*) {
        margin-top: 5px;
      }
      .message-timestamp {
        font-size: 10px;
        color: #c1c8cd;
      }
      .message-feedback {
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
        flex-grow: 1;
        min-width: fit-content;
        text-align: end;
        .cec-icon {
          margin-left: 2px;
        }
      }
    }
  }

  &.user-message {
    justify-content: end;

    &:not(:has(+ .user-message)) {
      .message-bubble::before {
        content: "";
        position: absolute;
        bottom: -12px;
        right: 8px;
        width: 0;
        height: 0;
        border: 6px solid;
        border-color: #b2d8f5 #b2d8f5 transparent transparent;
      }

      .message-bubble::after {
        content: "";
        position: absolute;
        bottom: -8px;
        right: 9.5px;
        width: 0;
        height: 0;
        border: 4px solid;
        border-color: #e0f6ff #e0f6ff transparent transparent;
      }
    }

    .message-bubble {
      border: 2px solid #b2d8f5;
      background-color: #e0f6ff;
    }
  }

  &.agent-message {
    .message-bubble {
      border: 1px solid #f3f4f5;
      background-color: #ffffff;
    }
  }
}