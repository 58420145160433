.notification-table .ant-table {
  color: #fff;
  background: transparent;
  .ant-table-tbody > tr {
    &.ant-table-placeholder:hover > td {
      background: transparent;
    }
    > td {
      border: none;
    }
  }
  .ant-empty-normal, .ant-statistic-content {
    color: #fff;
  }
  .table-title {
    margin-bottom: 5px;
  }
  .actions-wrapper {
    display: flex;
    > *:not(:last-child) {
      margin-right: 10px;
    }
    .round-icon {
      border: 1px solid #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cec-icon {
      width: 22px;
      height: 22px;
      &.cec-icon-chevron {
        transform: rotate(180deg);
      }
    }
  }
}

.notification-wrapper {
  min-width: 600px;
  .ant-tooltip-inner {
    background-color: #33424f;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}
