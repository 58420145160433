.home-row-1-container {
  text-align: center;
  height: 500px;
  background-color: #314351;

  .home-row-1 {
    padding: 0 5px;
    .ant-col {
      padding: 0 5px;
    }
  }
  h2 {
    color: #fff;
    margin-top: 54px;
    margin-bottom: 18px;
    font-size: 23px;

    &:before {
      content: '';
      display: block;
      width: 48px;
      height: 48px;
      margin: 0 auto 48px;
    }
  }
  p {
    color: #fff;
  }
}

.home-row-1-col-1 h2:before {
  background-image: url('../../../assets/img/home/row-1-card-1.png');
}

.home-row-1-col-2 h2:before {
  background-image: url('../../../assets/img/home/row-1-card-2.png');
}

.home-row-1-col-3 h2:before {
  background-image: url('../../../assets/img/home/row-1-card-3.png');
}

.home-row-2-container {
  padding-top: 100px;
  padding-bottom: 80px;
  color: #314351;
  text-align: center;
  .ant-col {
    padding: 0 5px;
  }
}

.home-row-3-container {
  padding-top: 100px;
  padding-bottom: 80px;
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  background: url('../../../assets/img/home/row-3.png') no-repeat 100% 100%;

  h2 {
    margin: 0 0 30px;
    text-align: center;
    font-size: 23px;
    line-height: 1.2;
    color: #fff;
  }

  ul {
    padding-left: 30px;

    li {
      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 15px;
        margin: 0 10px 0 -30px;
      }
      + li {
        margin-top: 10px;
      }
    }
  }
}

.home-row-4-container {
  padding-top: 100px;
  padding-bottom: 80px;
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  background: #314351;
  text-align: center;

  h2 {
    margin: 0 0 40px;
    color: #fff;
    font-size: 23px;
    line-height: 1.2;
  }

  .btn-action {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    padding: 0 19px;

    &:active {
      background-color: fade(#fff, 15%);
    }

    &:hover,
    &:focus,
    &:active {
      text-shadow: 0.03em 0 0 #fff;
    }
  }
}