.account {
  .icon-button {
    padding-right: 25px;
  }
}

.cec-login {
  padding: 40px;
  .modal-strip {
    width: 5px;
    overflow: hidden;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    background-image: url('../../../assets/img/ciena-strip.svg');
    background-size: 100% 100%;
  }
  .cec-icon-ciena {
    width: 120px;
    height: 50px;
    margin-bottom: 50px;
  }
  .app-name {
    margin: -30px 0 50px;
    color: #000;
    font-weight: 700;
    font-size: 23px;
  }
  .login-buttons {
    > * {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      color: #0271bc;
    }
    .button-logo {
      display: inline-block;
      height: 18px;
      margin-right: 16px;
      vertical-align: middle;
      width: 18px;
      background-size: contain;
      &.ciena {
        background-image: url('../../../assets/img/ciena-favicon.png');
      }
      &.salesforce {
        background-image: url('../../../assets/img/salesforce-favicon.png');
      }
    }
  }
}

.account-menu {
  &.ant-dropdown-menu {
    padding: 0;
    min-width: 120px;
    .ant-dropdown-menu-item-divider {
      margin: 0;
      background-color: #e2e2e2;
      &.thick-divider {
        height: 4px;
      }
    }
    .ant-dropdown-menu-item {
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
      padding: 0 15px;
      height: 30px;
      font-size: 14px;
      color: #272425;
      &.logout-item {
        color: #0271bc;
      }
      &:hover {
        background-color: rgba(0, 158, 239, 0.07);
      }
      &:active {
        background-color: #e6eef2;
      }
      &:focus {
        box-shadow: 0 0 0 1px #009eef;
      }
    }
  }
}

#loginWithCiena {
  display: none;
}
