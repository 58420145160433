.admin-eula-container {
  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-label > label {
      color: #637582;
      font-weight: bold;
    }
    .ant-form-item-control-input-content {
      color: #231f20;
    }
  }
}