.audit-container {
  .ant-list {
    margin-top: 10px;
    .phase-item {
      background-color: white;
      position: relative;
      > div {
        z-index: 1;
      }
      .background-div {
        position: absolute;
        z-index: 0;
        opacity: 0.15;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .ant-alert-warning {
        background-color: unset;
        border: unset;
        padding: 0;
      }
    }
  }
  .modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: normal;
    }
  }
}