.ant-modal-body {
  .ant-card {
    border: none;
    box-shadow: none;
    background: transparent;
    .ant-card-head {
      border: none;
      background: transparent;
      padding: 0;
    }
    .ant-card-body {
      padding: 0;
    }
  }
}
