@import '~antd/dist/antd.less';

body {
  line-height: normal;
}

ul {
  list-style: none;
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid #eee;
}

iframe {
  border: none;
  width: 100%;
  height: 100vh;
}

#body > div:nth-child(2) {
  padding-top: 50px;
  min-width: 1200px;
}

.page {
  padding: 30px;
  background-color: #f5f6f7;
  .page-title {
    font-size: 36px;
    color: #314351;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  &.white-bg {
    min-height: calc(100vh - 180px);
    background-color: #fff;
    border-left: 1px solid #E8E8EF;
    border-right: 1px solid #E8E8EF;
  }
  .ant-collapse {
    height: 100%;
  }
}

.container {
  width: 1176px;
  margin: 0 auto;
}

.cols-container {
  > *:not(:last-child) {
    padding-right: 30px;
  }
}


/**
 * Overrides the styles of Ant Modal
 */
.ant-modal {
  min-width: 530px;
}
.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.8);
}
.ant-modal-content {
  box-shadow: 0 25px 125px 0 rgb(0 0 0 / 30%);
}
.ant-modal-header {
  border-color: #e2e2e2;
  padding: 12px 25px;
}
.ant-modal-title {
  font-size: 20px;
  font-weight: 400;
  color: #414141;
}
.ant-modal-body {
  background-color: rgba(240, 242, 244, 0.5);
  padding: 25px;
  max-height: calc(100vh - 250px);
  overflow: auto;
  box-shadow: inset 0 10px 8px -10px rgb(0 0 0 / 10%),
    inset 0 -10px 8px -10px rgb(0 0 0 / 10%);
  
    .modal-title {
      color: #231f20;
      font-size: 16px;
      font-weight: bold;
    }

  .modal-subtitle {
    color: #231f20;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .modal-info {
    color: #231f20;
    line-height: 1.2;
    margin-bottom: 8px;
  }
}
.unset-height-modal {
  .ant-modal-body {
    max-height: unset;
  }
}

.ant-modal-footer {
  padding: 10px 25px;
  border-color: #e2e2e2;
  background-color: rgba(255, 255, 255, 0.95);
}

/**
 * Override the styles of Ant Button
 */
.ant-btn {
  &[disabled] {
    opacity: 0.3;
  }
  // text button
  &.ant-btn-text,
  &.ant-btn-text[disabled] {
    background-color: inherit;
    color: #0271bc;
    border-color: transparent;
    &:hover:not([disabled]) {
      background-color: rgba(0, 158, 239, 0.07);
    }
    &:focus:not(:active):not([disabled]) {
      border-color: #009eef;
    }
    &:active:not([disabled]) {
      background-color: #e6eef2;
    }
  }

  // primary button
  &.ant-btn-primary,
  &.ant-btn-primary[disabled] {
    color: #fff;
    background-color: #0271bc;
    border-color: transparent;
    &:hover:not([disabled]) {
      background-color: #009eef;
    }
    &:focus:not(:active):not([disabled]) {
      border-color: #43c9f7;
      background-color: #009eef;
      box-shadow: 0 0 4px 0 #43c9f7;
    }
    &:active:not([disabled]) {
      background-color: #0271bc;
    }

    &.ant-btn-red,
    &.ant-btn-red[disabled] {
      background-color: #cc3405;
      &:hover:not([disabled]) {
        background-color: #f93010;
      }
      &:focus:not(:active):not([disabled]) {
        background-color: #f93010;
      }
      &:active:not([disabled]) {
        background-color: #a92b04;
      }
    }

    &.ant-btn-green,
    &.ant-btn-green[disabled] {
      background-color:#359e35;
      &:hover:not([disabled]) {
        background-color: 	#55c249;
      }
      &:focus:not(:active):not([disabled]) {
        background-color: 	#55c249;
      }
      &:active:not([disabled]) {
        background-color: #2c832c;
      }
    }
  }
}
.ant-btn {
  .cec-icon {
    width: 16px;
    height: 16px;
    & + span {
      margin-left: 5px;
    }
  }
  span + .cec-icon {
    padding-left: 5px;
  }
}

/**
 * Overrides the styles of Ant Card
 */
.ant-card {
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

/**
 * Override the styles of Ant Form
 */
.ant-form.sub-form {
  padding: 0 20px;
}
.ant-form-item {
  margin-bottom: 12px;
}
.ant-input-number.ant-input-number-in-form-item {
  width: 100%;
}
.form-item-with-action .ant-form-item-label > label {
  width: 100%;
  justify-content: space-between;
}

/**
 * Overrides the styles of Ant Table
 */
.ant-table-thead > tr > th {
  background-color: #fff;
  color: #414141;
  font-weight: 700;
}
table > tbody > tr > td, table > thead > tr > th {
  border-color: #e2e2e2 !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: rgba(0,158,239,.11);
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(0,158,239,.2);
}
.ant-table-cell {
  .ant-tree {
    background: transparent;
    .ant-tree-treenode {
      padding: 0;
      span {
        color: #000 !important;
      }
      &::before {
        background: transparent !important;
      }
    }
  }
}
// Custom Table styles
.table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .table-toolbar {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-select {
      min-width: 200px;
    }
  }
  .ant-table-wrapper {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-bottom: none;
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 25px;
    background-color: rgba(255, 255, 255, 0.85);
    color: #676767;
    border: 1px solid #e2e2e2;
    > *:last-child button {
      margin-left: 8px;
    }
  }
}

.inner-table.ant-table-wrapper {
  border: none;
  background-color: unset;
  overflow: unset;
  margin: 10px 10px 10px 0;
}

.table-row-expand-no-border {
  .ant-table-row-expand-icon-cell {
    border-right: none !important;
  }
}

/**
 * Override the styles of Ant Description
 */
.ant-descriptions {
  .cec-icon {
    color: #1890ff;
    margin-left: 5px;
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
  .desc-item-with-action {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

/**
 * Override the styles of Ant List
 */
.ant-list-item {
  .ant-form-item {
    margin-bottom: 0;
  }
}
.ant-list-item-action {
  .cec-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: #0271bc;
  }
}

/**
 * Override the styles of Ant Statistic
 */
.ant-statistic, .ant-statistic-content {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

/**
 * Override the styles of Ant Input
 */
 .ant-input.ant-input-borderless {
  padding: 0;
 } 
.ant-input-suffix {
  .cec-icon {
    color: #1890ff;
    margin-left: 5px;
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
}

/**
 * Override the styles of Ant Picker
 */
.ant-picker-range {
  width: 100%;
}

/**
 * Override the styles of Ant Collapse
 */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0;
}

.folder-collapse.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 5px 0;
      .ant-collapse-arrow {
        vertical-align: 0;
        margin-right: 5px;
        .anticon:first-child {
          margin-right: 10px;
        }
      }
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .ant-collapse-item-active {
    > .ant-collapse-header {
      font-weight: 500;
      color: #0271bc;
    }
  }
}

/**
 * Override the styles of Ant Spin
 */
.table-loading.ant-spin {
  display: block;
  margin-top: 50px;
}

.highlight-info {
  color: red;
  font-weight: bold;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

/**
 * Override the styles of Ant Tree
 */
.treenode-leaf::before {
  background: unset !important;
}

/**
 * Override the styles of react-file-viewer
 */
.pg-viewer-wrapper{ 
  overflow: unset !important;
  .pg-viewer {
    width: 100%;
  }
}

/**
 * Add CKEditor CSS
 */
.marker {
	background-color: Yellow;
}

/**
 * Add Chatwoot CSS
 */
 @media only screen and (min-width: 667px) {
  .front .woot-widget-holder {
    width: 800px !important;
  }
}