.lab-details-container {
  .device-img {
    max-width: 100%;
    max-height: 150px;
  }
  .device-section-row {
    > *:not(:last-child) {
      margin-right: 10px;
    }
  }
  .user-visible-field {
    .ant-checkbox-disabled + span {
      color: unset;
    }
  }
  .icon--schedule--arrow {
    padding-left: 6px;
    font-size: 18px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid #1890ff;
    vertical-align: middle;
    &::before {
      content: "^";
      transform: rotate(90deg);
    }
  }
  .form-input-hidden {
    .ant-form-item-control {
      display: none;
    }
  }
}
